var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { "max-width": "300", type: "avatar" },
          })
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.openNotifications } },
                [
                  _c("img", {
                    class: [_vm.invertIcon ? "make-white-svg" : ""],
                    attrs: {
                      src: require("@/assets/images/icons/icon-notifications.svg"),
                    },
                  }),
                ]
              ),
              _vm.newNotificationCount > 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "notification-count pa-3 cursor--pointer d-flex align-center justify-center white--text error lighten2",
                      on: { click: _vm.openNotifications },
                    },
                    [_vm._v(" " + _vm._s(_vm.newNotificationCount) + " ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "mew-overlay",
        {
          attrs: {
            footer: {
              text: "Need help?",
              linkTitle: "Contact support",
              link: "mailto:support@xinfin.org",
            },
            title: "Notifications",
            "content-size": "large",
            "show-overlay": _vm.isOpenNotifications,
            close: _vm.closeNotifications,
          },
        },
        [
          _c(
            "v-sheet",
            {
              staticClass: "transparent",
              attrs: { "max-width": "735px", width: "100%" },
            },
            [
              _c(
                "v-sheet",
                {
                  staticClass:
                    "d-flex align-center justify-space-between mx-auto mb-6",
                  attrs: { color: "transparent", "max-width": "350px" },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "greenPrimary", large: "" } },
                        [_vm._v(" mdi-circle-medium ")]
                      ),
                      _vm._v(" Success "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "orangePrimary", large: "" } },
                        [_vm._v(" mdi-circle-medium ")]
                      ),
                      _vm._v(" Pending "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "redPrimary", large: "" } },
                        [_vm._v(" mdi-circle-medium ")]
                      ),
                      _vm._v(" Failed "),
                    ],
                    1
                  ),
                ]
              ),
              _vm.hasNotification
                ? _c(
                    "div",
                    { staticClass: "d-flex align-center justify-end" },
                    [
                      _c(
                        "v-sheet",
                        {
                          attrs: { color: "transparent", "max-width": "150px" },
                        },
                        [
                          _c("mew-select", {
                            attrs: {
                              items: _vm.items,
                              "has-filter": false,
                              "normal-dropdown": "",
                            },
                            on: { input: _vm.setSelected },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.notificationsByType, function (data, key) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.loading && _vm.notificationsByType.length > 0,
                        expression:
                          "!loading && notificationsByType.length > 0",
                      },
                    ],
                    key: key,
                    staticClass: "mt-2",
                  },
                  [
                    _c("mew-notification", {
                      attrs: { notification: data.notification },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.markNotificationAsRead(data)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.notificationsByType.length === 0,
                      expression: "notificationsByType.length === 0",
                    },
                  ],
                  staticClass: "pa-5 text-center",
                },
                [
                  _c("h3", { staticClass: "mb-5" }, [
                    _vm._v("No notifications to display for:"),
                  ]),
                  _c("h3", { staticClass: "break-hash" }, [
                    _vm._v(_vm._s(_vm.getXDCAddress(_vm.address))),
                  ]),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }